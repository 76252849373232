<template>
   <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
         <a-col style="width:220px">
           <span>用户手机号：</span>
           <a-input v-model="queryData.mobile" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:240px">
           <span>用户ID：</span>
           <a-input v-model="queryData.unionId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>充值/扣减类型：</span>
           <a-select allowClear placeholder='请选择' v-model="queryData.type">
               <a-select-option :value="1">小汇豆</a-select-option>
               <a-select-option :value="2">积分</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:180px">
           <span>名目：</span>
           <a-select allowClear placeholder='请选择' v-model="queryData.moduleType">
               <a-select-option :value="1">系统发放</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:400px">
           <span>操作时间：</span>
           <a-range-picker :show-time="showTime" v-model="operationTime"/>
        </a-col>
        <a-col style="width:220px">
           <span>积分类型：</span>
           <a-select allowClear placeholder='请选择' v-model="queryData.jifenType">
               <a-select-option :value="1">激活积分</a-select-option>
               <!-- <a-select-option :value="2">销售积分</a-select-option> -->
               <a-select-option :value="3">推广积分</a-select-option>
           </a-select>
        </a-col>
        <a-col>
           <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" style="margin-right:12px" type='primary'>查询</a-button>
           <a-button type='primary' style="margin-right:12px" @click="$refs.exportModal.show()" v-auth='"operateBonus:export"'>导出</a-button>
           <a-button type='primary' style="margin-right:12px" @click="$router.push({name: 'rechargeOptions'})" v-auth='"operateBonus:operate"'>充值/扣减</a-button>
        </a-col>
      </a-row>
      <a-table :columns="columns" :dataSource="tableData" :pagination="false" :rowKey="record => record.id"
         :loading="loading"
         :scroll="{x: true}"
         size="default">
      </a-table>
      <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />

      <ExportModal
         ref="exportModal"
         :exportUrl="exportUrl"
         :params="handleParams()"
         :total="total"
      >
      </ExportModal>
   </a-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import ExportModal from '@/components/ExportModal'
export default {
   name:'RechargeList',
   components: { ExportModal },
   data() {
      return {
         exportUrl: api.marketManage.exportBonusList,
         showTime:{
            format: 'HH:mm:ss',
            defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('00:00:00', 'HH:mm:ss')]
         },
         queryData:{
            mobile: undefined,
            unionId: undefined,
            type: undefined,
            moduleType: undefined,
            jifenType: undefined,
            beginTime: undefined,
            endTime: undefined
         },
         //默认展示近一周的时间
         operationTime: [moment(moment().subtract(1, 'weeks').format('YYYY-MM-DD')), moment(moment().format('YYYY-MM-DD 23:59:59'))],
         pageNum: 1,
         total: 0,
         pageSize: 10,
         loading: false,
         columns: [
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (_, __, index) => {
                  return index + 1
               }
            },
            {
               title: '用户ID',
               dataIndex:'unionId',
               align: 'center',
               ellipsis:true
            },
            {
               title: '手机号',
               dataIndex:'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '积分类型',
               dataIndex:'jifenTypeStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '数值',
               dataIndex:'num',
               align: 'center',
               ellipsis:true
            },
            {
               title: '充值/扣减类型',
               dataIndex:'typeStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '名目',
               dataIndex:'moduleTypeStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '操作时间',
               dataIndex:'updateTime',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
            {
               title: '备注',
               dataIndex:'remark',
               align: 'center',
               ellipsis:true
            },
         ],
         tableData: []
      }
   },
   watch: {
      operationTime: {
         handler (val) {
            this.queryData.beginTime = val[0] ? val[0].valueOf() / 1000 : undefined
            this.queryData.endTime = val[1] ? val[1].valueOf() / 1000 : undefined
         },
         immediate: true
      }
   },
   activated () {
      this.loadTableData()
   },
   methods: {
      loadTableData () {
         this.loading = true
         api.marketManage.operateBonusList(this.handleParams()).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
               this.total = res.data.total
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      },
      handleParams () {
         let p = {
            account: this.$store.state.userInfo.account,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            ...this.queryData
         }
         return p
      }
   }
}
</script>
