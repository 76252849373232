<template>
  <a-modal
    v-model="showModal"
    title="导出"
    :mask-closable="false"
    :loading="true"
    ok-text="导出"
  >
    <div class="content">
      当前检索条件查询出 <span>{{ total }}</span> 条记录<br />
      <!-- 当前页面：第{{page}}页 -->
    </div>

    <div slot="footer" class="progress" v-if="exportStatus">
      <p style="float: left" v-if="execRatio >= 100">
        导出完成：导出成功！请前往<a
          @click="
            () => {
              this.showModal = false;
              this.$router.push({ name: 'downloadFileManage' });
            }
          "
          >【综合管理】【文件列表】</a
        >下查看！
      </p>
      <p style="float: left" v-else>正在导出：</p>
      <a-progress
        style="margin-top: 10px"
        :percent="execRatio"
        :stroke-width="10"
        :status="progressStatus"
        text-inside
      />
    </div>
    <div slot="footer">
      <a-button type="primary" @click="exportAll" key="3" v-if="!exportStatus"
        >导出全部</a-button
      >
    </div>
  </a-modal>
</template>

<script>
let timer;
import Cookies from 'js-cookie';
import api from '@/api';
import { request } from '@/api/request';

export default {
  name: 'ExportModal',
  props: {
    exportUrl: {
      type: String
    },
    params: {
      type: Object
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showModal: false,
      exportStatus: false,
      exportPage: 1,
      execRatio: 0,
      exportType: false,
      progressStatus: 'active',
      userId: ''
    };
  },
  watch: {
    showModal(val) {
      if (!this.showModal) {
        this.exportStatus = false;
        clearTimeout(timer);
      }
    }
  },
  computed: {
    pageTotal() {
      const page = (this.total - 1) / this.pageSize + 1;
      return Math.trunc(page);
    }
  },
  mounted() {
    this.exportStatus = false;
  },
  methods: {
    exportAll() {
      let _this = this;
      // 如果无数据，则不调用导出接口
      if (!this.total) {
        this.$notification.open({
          message: '导出失败',
          description: '无数据导出，请修改查询条件后重试',
          duration: 2.5
        });
        this.showModal = false;
        return;
      }
      this.exportStatus = true;
      let params = {
        ...this.params,
        account: Cookies.get('accountName') || Cookies.get('account'), // 988888	管理后台账号
        platApp: 5 // 2	管理后台：0 bgs，1 bbc后台，2商家后台，3 oms后台，4 oms大屏
      };
      if (this.userId) {
        params['userId'] = this.userId;
      }
      request
        .post(this.exportUrl, params)
        .then(res => {
          if (res.code == this.successCode) {
            this.id = res.data.id;
            this.execRatio = (res.data.execRatio * 100).toFixed(2);
            _this.getExecRatio();
          } else {
            this.$message.error(res.msg);
            // this.exportStatus = false
          }
        })
        .catch(() => {
          this.exportStatus = false;
        });
    },
    show(params) {
      this.showModal = true;
      if (params && params.userId) {
      }
    },
    getExecRatio() {
      console.log('调用getExecRatio');
      // this.execRatio++;
      let _this = this;
      let params = {
        taskId: this.id
      };
      api.integratedManage
        .downloadExecRatio(params)
        .then(res => {
          if (res.code == this.successCode) {
            this.execRatio = (res.data.excelTask.execRatio * 100).toFixed(2);
            if (this.execRatio < 100) {
              timer = setTimeout(function() {
                _this.getExecRatio(this.id);
              }, 3000);
            } else {
              this.progressStatus = '';
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.check-box {
  float: left;
  font-size: 13px;
  margin-top: 7px;
}

.content {
  text-align: center;
  line-height: 30px;
  font-size: 15px;
}
.content span {
  font-weight: bolder;
}
</style>
